import { render, staticRenderFns } from "./editScheduleInstModal.vue?vue&type=template&id=2065bee9&scoped=true"
import script from "./editScheduleInstModal.vue?vue&type=script&lang=js"
export * from "./editScheduleInstModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2065bee9",
  null
  
)

export default component.exports